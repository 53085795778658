const footerLinks = [
  {
    title: 'Company',
    links: [
      { title: 'About', link: '/about' },
      { title: 'Careers', link: '/careers' },
      { title: 'Affiliates', link: '/affiliates' },
      { title: 'Terms and policy', link: '/termsandpolicy' },
    ]
  },

  {
    title: 'Resources',
    links: [
      { title: 'Articles', link: '/articles' },
      { title: 'Blog', link: '/blog' },
      { title: 'Chart Sheet', link: '/chart-sheet' },
      { title: 'Code Challenges', link: '/code-challenges' },
      { title: 'Docs', link: '/docs' },
      { title: 'Projects', link: '/projects' },
      { title: 'Videos', link: '/videos' },
      { title: 'Workspaces', link: '/workspaces' },
    ]
  },

  {
    title: 'Support',
    links: [
      { title: 'Help Center', link: '/help-center' },
    ]
  },

  {
    title: 'Plans',
    links: [
      { title: 'Paid Membership', link: '/paid-membership' },
      { title: 'For Students', link: '/for-students' },
      { title: 'Business Solutions', link: '/business-solutions' },
    ]
  },

  {
    title: 'Community',
    links: [
      { title: 'Forums', link: '/forums' },
      { title: 'Chapters', link: '/chapters' },
      { title: 'Events', link: '/events' },
    ]
  },

  {
    title: "Subjects",
    links: [
      { title: "Al", link: "/al" },
      { title: "Cloud Computing", link: "/cloud-computing" },
      { title: "Code Foundations", link: "/code-foundations" },
      { title: "Computer Science", link: "/computer-science" },
      { title: "Cybersecurity", link: "/cybersecurity" },
      { title: "Data Analytics", link: "/data-analytics" },
      { title: "Data Science", link: "/data-science" },
      { title: "Data Visualization", link: "/data-visualization" },
      { title: "Developer Tools", link: "/developer-tools" },
      { title: "DevOps", link: "/devops" },
      { title: "Game Development", link: "/game-development" },
      { title: "IT", link: "/it" },
      { title: "Machine Learning", link: "/machine-learning" },
      { title: "Math", link: "/math" },
      { title: "Mobile Development", link: "/mobile-development" },
      { title: "Web Design", link: "/web-design" },
      { title: "Web Development", link: "/web-development" },
    ],
  },
  {
    title: "Languages",
    links: [
      { title: "Bash", link: "/bash" },
      { title: "C++", link: "/c++" },
      { title: "C#", link: "/csharp" },
      { title: "Go", link: "/go" },
      { title: "HTML & CSS", link: "/html-css" },
      { title: "Java", link: "/java" },
      { title: "JavaScript", link: "/javascript" },
      { title: "Kotlin", link: "/kotlin" },
      { title: "PHP", link: "/php" },
      { title: "Python", link: "/python" },
      { title: "R", link: "/r" },
      { title: "Ruby", link: "/ruby" },
      { title: "SQL", link: "/sql" },
      { title: "Swift", link: "/swift" },
    ],
  },
  {
    title: "Career building",
    links: [
      { title: "Career paths", link: "/career-paths" },
      { title: "Career services", link: "/career-services" },
      { title: "Interview prep", link: "/interview-prep" },
      { title: "Professional certification", link: "/professional-certification" },
      { title: "-", link: "/hi" },
      { title: "Full Catalog", link: "/full-catalog" },
      { title: "Beta Content", link: "/beta-content" }
    ]
  }
];

module.exports = footerLinks;
